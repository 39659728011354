import { Ref } from 'vue'
import { api } from '@/main'
import useListModel from './generic'
import { AiSpotlightVideo } from '@/api/spotlight_video'

type Args = Parameters<typeof api.spotlightVideos.spotlightVideosListSpotlightVideos>
const model = (...args: Args) => api.spotlightVideos.spotlightVideosListSpotlightVideos(...args)

export default (): {
    items: Ref<AiSpotlightVideo[]>;
    // eslint-disable-next-line
    fetchItems: (...args: Args) => Promise<void>;
    readonly end: Ref<boolean>;
    readonly error: Ref<boolean>;
    clearError: () => void;
    readonly loading: Ref<boolean>;
    // eslint-disable-next-line
    nextPage: () => Promise<void>;
} => useListModel(model)
