

import { syncRef, useUrlSearchParams, useVModels } from '@vueuse/core'
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import BarMenu from './BarMenu.vue'

export default defineComponent({
  components: { BarMenu },
  props: {
    from: {
      type: String,
    },
    to: {
      type: String,
    },
    factory: {
      type: String,
    },
    customer: {
      type: String,
    },
    procedure: {
      type: String,
    },
    tag: {
      type: String,
    },
    sort: {
      type: String,
      required: true,
    },
    customers: {
      type: Array,
    },
    // tags: {
    //   type: Set
    // },
    factories: {
      type: Array,
    },
    procedures: {
      type: Array,
    },
  },
  setup(props, { emit }) {
    // const tag = ref('')
    const {
      from: vFrom,
      to: vTo,
      factory: selectedFactory,
      customer: selectedCustomer,
      procedure: selectedProcedure,
      tag: selectedTag,
      sort: vSort,
    } = useVModels(props, emit)
    // const settings = ref(JSON.parse(localStorage.getItem('settings') || '{}'))

    const urlParams = useUrlSearchParams()
    onMounted(() => {
      if (urlParams.from) {
        fromVal.value = urlParams.from as string
      }
      if (urlParams.to) {
        toVal.value = urlParams.to as string
      }
      if (urlParams.factory) {
        selectedFactory.value = urlParams.factory as string
      }

      if (urlParams.customer) {
        selectedCustomer.value = urlParams.customer as string
      }
      if (urlParams.prc) {
        selectedProcedure.value = urlParams.prc as string
      }
      if (urlParams.tag) {
        selectedTag.value = urlParams.tag as string
      }
      if (urlParams.sort) {
        sortModel.value = urlParams.sort as string
      }
    })
    // for date
    // const fromVal = ref(moment().subtract(7, 'd').format('YYYY-MM-DDTHH:mm'))
    // const toVal = ref(moment().format('YYYY-MM-DDTHH:mm'))
    const fromVal = ref()
    const toVal = ref()
    watch(fromVal, () => {
      // vFrom.value = String(Math.floor(moment(fromVal.value).startOf('day').valueOf() / 1000))
      vFrom.value = fromVal.value
    })
    watch(toVal, () => {
      // vTo.value = String(Math.floor(moment(toVal.value).endOf('day').valueOf() / 1000))
      vTo.value = toVal.value
    })

    const sortModel = ref('dateTime')
    const sortAsc = ref(false)
    const sort = computed(() => {
      let s = [] as string[]
      switch (sortModel.value) {
        case 'dateTime':
          s = ['recordedDate.seconds', 'recordedDate.nanos']
          break
        case 'kpi1':
          s = ['kpi1']
          break
        case 'kpi2':
          s = ['kpi2']
          break
        case 'kpi3':
          s = ['kpi3']
          break
        case 'kpi4':
          s = ['kpi4']
          break
        case 'mAP':
          s = ['mAP']
          break
      }

      if (!sortAsc.value) {
        for (const i in s) {
          s[i] = '-' + s[i]
        }
      }

      return s.join(',')
    })
    syncRef(sort, vSort)

    const queryParams = computed(() => ({
      sort: sortModel.value,
      from: fromVal.value,
      to: toVal.value,
      factory: selectedFactory.value,
      customer: selectedCustomer.value,
      prc: selectedProcedure.value,
      tag: selectedTag.value,
      // tag: selectedTag.value
    }))

    const router = useRouter()
    const route = useRoute()
    const clear = () => {
      // tag.value = ''
      selectedFactory.value = ''
      selectedCustomer.value = ''
      selectedTag.value = ''
      selectedProcedure.value = ''
      sortModel.value = 'dateTime'
    }

    watch([sort, fromVal, toVal, selectedFactory, selectedCustomer, selectedProcedure, selectedTag], () => {
      router.replace({
        query: Object.fromEntries(
          Object.entries(
            Object.assign({}, route.query, queryParams.value),
          ).filter(([, v]) => !!v)),
      })
    })

    return {
      sortModel,
      sortAsc,
      fromVal,
      toVal,
      selectedFactory,
      selectedCustomer,
      selectedTag,
      selectedProcedure,
      // tag,
      clear,
    }
  },
})
