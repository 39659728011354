
import { defineComponent, onMounted, computed, ref, watch } from 'vue'
import debounce from 'lodash/debounce'
import moment from 'moment'
import KpiFilter from '@/components/KpiFilter.vue'
import EChart from '@/components/EChart.vue'
import useKpiResults from '@/models/list/kpi_results'
import useSession from '@/models/get/session'
import useSpotlights from '@/models/list/spotlight_videos'

export default defineComponent({
  name: 'BenchmarkAnalytics',
  components: {
    KpiFilter,
    EChart,
  },
  props: {
    date: {
      required: true,
      type: String,
    },
    customerName: {
      required: false,
      type: String,
    },
  },
  setup(props) {
    const { fetchItems: fetchSpotlights, items: spotlights } = useSpotlights()
    const { fetchItem: fetchSession, item: foundSession } = useSession()
    const { fetchItems: fetchKpiResults, items: kpiResults } = useKpiResults()
    const from = ref(moment(props.date).startOf('day').format('YYYY-MM-DDTHH:mm'))
    const to = ref(moment(props.date).endOf('day').format('YYYY-MM-DDTHH:mm'))
    const counts = ref<number[]>([])
    const sort = ref<string>('')
    const cycleTime = ref(new Map())
    const dates = ref<string[]>([])
    const kpi1 = ref<number[]>([])
    const kpi2 = ref<number[]>([])
    const kpi3 = ref<number[]>([])
    const kpi4 = ref<number[]>([])
    const kpi5 = ref<number[]>([])
    const mAP = ref<number[]>([])
    const factory = ref('')
    const procedure = ref('')
    const customer = ref(props.customerName)
    const tag = ref('')
    const presetMap = ref(new Map<string, string>())
    const factoryMap = ref(new Map<string, [string]>())
    const customers = ref<string[]>([])
    const tags = ref(new Set())
    const factories = ref<string[]>([])
    const procedures = ref<string[]>([])
    const factorySelection = ref<string[]>([])
    const flag = ref(0)
    const o = ref()
    const localDates = ref<string[]>([])
    // const label = ['mAP', 'kpi1', 'kpi2', 'kpi3', 'kpi4']
    // const toTimestamp = (value: string): number => {
    //   const date = new Date(value)
    //   return date.valueOf() / 1000
    // }
    const toLocalDate = (date: string): string => {
      const local = moment.utc(date).local().format('YYYY-MM-DD HH:mm:ss')
      return local
    }
    // const toUTCDate = (date: string): string => {
    //   const utcDate = moment(date).utc().format('YYYY-MM-DD HH:mm:ss')
    //   return utcDate
    // }

    const tagSplit = (tags: Array<string>) => {
      let temp = ''
      for (let i = 0; i < tags.length; i++) {
        temp += tags[i] + '  '
      }
      return temp.slice(0, -1)
    }

    const spotlightFilter = computed(() => {
      const q: unknown[] = []
      if (from.value || to.value) {
        q.push({ 'recordedDate.seconds': { $gte: moment(from.value).utc().valueOf() / 1000 } })
        q.push({ 'recordedDate.seconds': { $lte: moment(to.value).utc().valueOf() / 1000 } })
      }
      if (tag.value) {
        const t = tag.value.split(',')
        if (t.length === 1) {
          q.push({ tags: { $in: [tag.value] } })
        } else {
          const temp = ref<unknown[]>([])
          for (const s of t) {
            const c = { tags: s }
            temp.value.push(c)
          }
          q.push({ $and: temp.value })
        }
      }
      if (customer.value) {
        q.push({ customer: customer.value })
      }
      if (factory.value) {
        const sessions = factoryMap.value.get(factory.value)

        if (sessions?.length && sessions.length >= 0) {
          console.log(factory.value)
          q.push({ session: { $in: sessions } })
        }
      }
      if (procedure.value) {
        q.push({ procedure: procedure.value })
      }
      return q.length > 0
        ? JSON.stringify({
          $and: q,
        })
        : ''
    })

    // const kpiFilter = computed(() => {
    //   const q: unknown[] = []
    //   if (from.value || to.value) {
    //     q.push({ 'created.seconds': { $gte: toTimestamp(from.value) } })
    //     q.push({ 'created.seconds': { $lte: toTimestamp(to.value) } })
    //   }
    //   return q.length > 0
    //     ? JSON.stringify({
    //       $and: q,
    //     })
    //     : ''
    // })

    const gatherData = async () => {
      o.value = null
      factories.value = []
      dates.value = []
      kpi1.value = []
      kpi2.value = []
      kpi3.value = []
      kpi4.value = []
      kpi5.value = []
      mAP.value = []
      for (let i = 0; i < spotlights.value.length; i++) {
        // console.log(toTimestamp(String(spotlights.value[i].recordedDate)))
        if (spotlightMapKpi.value[String(spotlights.value[i].name)] !== undefined) {
          if (spotlightMapKpi.value[String(spotlights.value[i].name)]?.kpi1 !== -1) {
            kpi1.value.push(Number(spotlightMapKpi.value[String(spotlights.value[i].name)]?.kpi1))
          }
          if (spotlightMapKpi.value[String(spotlights.value[i].name)]?.kpi2 !== -1) {
            kpi2.value.push(Number(spotlightMapKpi.value[String(spotlights.value[i].name)]?.kpi2))
          }
          if (spotlightMapKpi.value[String(spotlights.value[i].name)]?.kpi3 !== -1) {
            kpi3.value.push(Number(spotlightMapKpi.value[String(spotlights.value[i].name)]?.kpi3))
          }
          if (spotlightMapKpi.value[String(spotlights.value[i].name)]?.kpi4 !== -1) {
            kpi4.value.push(Number(spotlightMapKpi.value[String(spotlights.value[i].name)]?.kpi4))
          }
          if (spotlightMapKpi.value[String(spotlights.value[i].name)]?.kpi5 !== -1) {
            kpi5.value.push(Number(spotlightMapKpi.value[String(spotlights.value[i].name)]?.kpi5))
          }
          if (spotlightMapKpi.value[String(spotlights.value[i].name)]?.mAP !== -1) {
            mAP.value.push(Number(spotlightMapKpi.value[String(spotlights.value[i].name)]?.mAP))
          }
          customers.value.push(String(spotlights.value[i]?.customer))
          procedures.value.push(String(spotlights.value[i]?.procedure))
          factories.value.push(String(spotlights.value[i]?.factory))
          // const sessionID = String(spotlights.value[i].unit?.split('_')[4])
          const sessionID = String(spotlights.value[i].session?.split('/')[1])
          if (!presetMap.value.has(sessionID)) {
            if (sessionID) {
              await fetchSession({ session: sessionID })
              const fac = foundSession.value.source
              if (fac) {
                const temp = factoryMap.value.get(fac)
                if (temp) {
                  temp.push('sessions/' + sessionID)
                  factoryMap.value.set(fac, temp)
                } else {
                  factoryMap.value.set(fac, ['sessions/' + sessionID])
                }
                presetMap.value.set(sessionID, fac)
              }
            }
          }
        }
      }
    }

    const spotlightVideosByKpiResult = computed(() => Object.fromEntries(
      kpiResults.value.map(u => [
        u.name,
        spotlights.value.find(s => u.name?.includes(s.name as string)),
      ]),
    ))

    const spotlightMapKpi = computed(() => Object.fromEntries(
      spotlights.value.map(u => [
        u.name,
        kpiResults.value.find(s => s.name?.includes(u.name as string)),
      ]),
    ))

    const fetch = debounce(async () => {
      await fetchSpotlights({
        pageSize: -1,
        orderBy: sort.value,
        filter: spotlightFilter.value,
      })

      await fetchKpiResults({
        pageSize: -1,
        spotlight: '-',
        // filter: kpiFilter.value,
        orderBy: sort.value,
      })
      console.log(kpiResults.value)
      // console.log(spotlightVideosByKpiResult.value)
      console.log(spotlights.value)
      console.log(spotlightMapKpi.value)
      await gatherData()
      // counts.value = Array(kpiResults.value.length).fill(0)
      // console.log(counts.value)
      flag.value += 1
    }, 250)

    onMounted(async () => {
      await Promise.all([
        fetch(),
      ])
    })

    // const router = useRouter()
    // const Handler = (params: any) => {
    //   console.log(params)
    //   console.log(1)
    //   router.push({ name: 'BenchmarkBreakdown', query: { benchmarkId: params.name.split('/')[1] } })
    // }
    watch([spotlightFilter, sort], () => {
      fetch()
    })
    watch([flag], () => {
      o.value = {
        title: [
          {
            text: 'Box & Whiskers',
            left: 'center',
          },
          // {
          //   text: 'a',
          //   borderColor: '#999',
          //   borderWidth: 1,
          //   textStyle: {
          //     fontWeight: 'normal',
          //     fontSize: 14,
          //     lineHeight: 20
          //   },
          //   left: '10%',
          //   top: '90%'
          // }
        ],
        dataset: [
          {
            // prettier-ignore
            source: [
              mAP.value,
              kpi1.value,
              kpi2.value,
              kpi3.value,
              kpi4.value,
              kpi5.value,
            ],
          },
          {
            transform: {
              type: 'boxplot',
              config: { itemNameFormatter: 'kpi {value}' },
            },
            // transform: {
            //   type: 'boxplot',
            //   config: {
            //     itemNameFormatter: function (params:number) {
            //       return label[params]
            //     }
            //   }
            // }
          },
          {
            fromDatasetIndex: 1,
            fromTransformResult: 1,
          },
        ],
        tooltip: {
          trigger: 'item',
          position: 'top',
          appendToBody: true,
          axisPointer: {
            type: 'shadow',
          },
        },
        grid: {
          left: '10%',
          right: '10%',
          bottom: '15%',
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          nameGap: 30,
          splitArea: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          name: 'kpi value',
          splitArea: {
            show: true,
          },
        },
        series: [
          {
            name: 'boxplot',
            type: 'boxplot',
            datasetIndex: 1,
          },
          {
            name: 'outlier',
            type: 'scatter',
            datasetIndex: 2,
          },
        ],
      }
    })
    return {
      from,
      to,
      counts,
      procedure,
      cycleTime,
      spotlights,
      o,
      factory,
      customer,
      tag,
      sort,
      customers,
      tags,
      factories,
      factorySelection,
      procedures,
      localDates,
      kpi1,
      kpi2,
      kpi3,
      kpi4,
      mAP,
      spotlightVideosByKpiResult,
      kpiResults,
      toLocalDate,
      spotlightMapKpi,
      tagSplit,
    }
  },
})
